import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const ForgotPasswordPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    useEffect(() => {
        if (config.config && user?.loaded && !user.loggedIn) {
            handleForgotPassword();
        }
    }, [config, user]);

    const forgotPasswordUrl = config?.config?.forgot_password_host_uri ?? null;

    const handleForgotPassword = async () => {
        if (config.config?.forgot_password_host_uri) {
            window.location.replace(forgotPasswordUrl);
        }
    };

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            {user?.loaded && user.loggedIn ? <Redirect to='/enter' /> : null}
            <div className='redirect-container'>
                <p className='redirect-message'>Redirecting to Forgot Password page:</p>
                <a href={forgotPasswordUrl} className='redirect-link'>
                    {forgotPasswordUrl}
                </a>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
