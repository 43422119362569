module.exports = {
    routes: {
        '': {
            title: 'Home',
            pageHeader: 'Home',
            className: 'home',
        },
        home: {
            title: 'Home',
            pageHeader: 'Home',
            className: 'home',
        },
        register: {
            title: 'Sign Up',
            className: 'register',
        },
        registerverificationerror: {
            title: 'Register Verification Error',
            className: 'register-verification-error',
        },
        awaitaccountconfirmation: {
            title: 'Account Confirmation',
            className: 'await-account-confirmation',
        },
        accountconfirm: {
            title: 'Account Confirm',
            pageHeader: 'Account Confirm',
            className: 'account-confirm',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Account Confirm', path: '/account-confirm' },
            ],
        },
        passwordexpiring: {
            title: 'Password Expiring',
            className: 'password-expiring',
            loggedInOnly: true,
            currentSection: 'password-expired',
            disableRedirect: 'change-password',
        },
        passwordexpired: {
            title: 'Password Expired',
            className: 'password-expired',
            loggedInOnly: true,
            currentSection: 'password-expired',
            disableRedirect: 'change-password',
        },
        recoverpassword: {
            title: 'Recover Password',
            className: 'recover-password',
        },
        postclaim: {
            title: 'Enter Tickets',
            className: 'post-claim',
            loggedInOnly: true,
            currentSection: 'post-claim',
            disableRedirect: 'survey',
        },
        '': {
            title: 'Home',
            pageHeader: 'Home',
            className: 'home',
        },
        home: {
            title: 'Home',
            pageHeader: 'Home',
            className: 'home',
        },
        register: {
            title: 'Sign Up',
            className: 'register',
        },
        registerverificationerror: {
            title: 'Register Verification Error',
            className: 'register-verification-error',
        },
        awaitaccountconfirmation: {
            title: 'Account Confirmation',
            className: 'await-account-confirmation',
        },
        accountconfirm: {
            title: 'Account Confirm',
            pageHeader: 'Account Confirm',
            className: 'account-confirm',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Account Confirm', path: '/account-confirm' },
            ],
        },
        recoverpassword: {
            title: 'Recover Password',
            className: 'recover-password',
        },
        promotions: {
            title: 'Promotions/Achievements',
            pageHeader: 'Promotions/Achievements',
            className: 'promotions',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Promotions', path: '/promotions' },
            ],
        },
        drawings: {
            title: 'Points for Drawings',
            pageHeader: 'Points for Drawings',
            className: 'pointsForDrawings',
            feature: 'pointsForDrawings',
            featureDisabledRedirectPath: '/home',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Points for Drawings', path: '/drawings' },
            ],
        },
        'pointsfordrawingdetails:sweepId': {
            title: '{{PROMOTION_NAME}}',
            pageHeader: '{{PROMOTION_NAME}}',
            className: 'points-for-drawing-details',
            feature: 'pointsForDrawings',
            featureDisabledRedirectPath: '/home',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Points for Drawings', path: '/drawings' },
                { name: '{{PROMOTION_NAME}}', path: '' },
            ],
        },
        secondchancepromotion: {
            title: '{{PROMOTION_NAME}}',
            pageHeader: '{{PROMOTION_NAME}}',
            className: 'second-chance-promotion',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Promotions', path: '/promotions#second-chance-promotions' },
                { name: '{{PROMOTION_NAME}}', path: '' },
            ],
            path: 'promotion',
        },
        promotion: {
            title: '{{PROMOTION_NAME}}',
            pageHeader: '{{PROMOTION_NAME}}',
            className: 'promotion-details',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Promotions/Achievements', path: '/promotions#achievements' },
                { name: '{{PROMOTION_NAME}}', path: '' },
            ],
            path: 'promotion',
        },
        feedback: {
            title: 'Help',
            pageHeader: 'Help',
            className: 'feedback',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Help', path: '/feedback' },
            ],
        },
        myprofile: {
            title: 'My Profile',
            pageHeader: 'My Profile',
            className: 'my-profile',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'My Profile', path: '/my-profile' },
            ],
            loggedInOnly: true,
            currentSection: 'my-profile',
            disableRedirect: 'change-password',
        },
        survey: {
            title: 'Survey',
            pageHeader: 'Survey',
            className: 'survey',
            loggedInOnly: true,
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Survey', path: '/survey' },
            ],
        },
        enter: {
            title: 'Ticket Entry',
            pageHeader: 'Ticket Entry',
            className: 'enter',
            loggedInOnly: true,
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Ticket Entry', path: '/enter' },
            ],
        },
        winners: {
            title: 'Drawings & Winners',
            pageHeader: 'Drawings & Winners',
            className: 'winners',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Drawings & Winners', path: '/winners' },
            ],
        },
        myactivity: {
            title: 'My Activity',
            pageHeader: 'My Activity',

            className: 'my-activity',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'My Activity', path: '/my-activity' },
            ],
            loggedInOnly: true,
        },
        referafriend: {
            title: 'Refer a Friend',
            pageHeader: 'Refer a Friend',
            className: 'refer-a-friend',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Refer a Friend', path: '/refer-a-friend' },
            ],
            loggedInOnly: true,
        },
        terms: {
            title: 'Terms of Service',
            className: 'terms',
        },
        updatepassword: {
            title: 'Update Password',
            className: 'update-password',
            loggedInOnly: true,
        },
    },
    title: 'Delaware Ensemble',
    promotion_id: '',
    jurisdiction: 'DE',
    jurisdictionName: 'Delaware',
    imgFolderName: 'de',
    promotion: 'ensemble_site',
    appUrl: '',
    lottoUrl: '',
    prodhost: '',
    uathost: '',
    feedbackIssueZone: 'SCBZ',
    defaultLoginLink: `/login`,
    defaultRegisterLink: '/register',
    successfulLoginRedirect: '/',
    getLoginLink: (app, redirect) => (app && redirect ? `` : this.defaultLoginLink),
    getRegisterLink: (app, redirect) => (app && redirect ? `` : this.defaultRegisterLink),
    getLogoutDestination: (hostURL, ptaLogout) => (ptaLogout != undefined ? ptaLogout : hostURL + '/home'),
    forgot: '/users/forgot',
    dashboardComponents: ['pfd'],
    needSSNToRegister: false,
    testing: {},
    modules: {
        hasWallet: false,
    },
    components: {
        recentDrawings: {
            defaultPageSize: 10,
        },
        rewardsHistory: {
            defaultPageSize: 5,
        },
        ticketHistory: {
            defaultPageSize: 5,
        },
    },
    resolveCurrency: (name) => {
        const currencyExchange = [
            {
                name: 'sweepstake',
                displayName: 'sweepstake',
                showQuantity: true,
                isPence: false,
                resolve: (amount) => {
                    return amount;
                },
            },
            {
                name: 'lotteryproducts',
                displayName: 'lotteryproducts',
                showQuantity: false,
                isPence: false,
                resolve: (amount) => {
                    return '';
                },
            },
            {
                name: 'promocash',
                displayName: 'promocash',
                showQuantity: true,
                isPence: true,
                resolve: (amount) => {
                    const amt = amount / 100;
                    return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }).format(amt);
                },
            },
            {
                name: 'default',
                displayName: 'default',
                showQuantity: true,
                isPence: false,
                resolve: (amount) => {
                    return amount;
                },
            },
        ];

        return currencyExchange.filter((item) => {
            return item.name === name || item.name === 'default';
        })[0];
    },
    numPromosToDisplayByDefault: 3,
    drawTicketLength: '19',
    drawFields: [{ name: 'field1', placeholder: 'xxxxxxxxxxxxxxxxxxx', size: '19', value: '' }],
    badTicketInputReplacement: /[o,O]/g, // Regex to remove bad input from ticket fields.
    /*
    Entry Number Format: GGG-BBBBB-X-TTT-XX
    Entry Input: 3 digit game number + 5 digit pack number + 1 digit check digit + 3 digit ticket number + 2 digit check digit
    */
    scratchTicketLengthRegex: '^[0-9]{26}$',
    scratchTicketLength: 26,
    scratchTicketFields: {
        gameNumberFields: [
            {
                name: 'gameNum',
                placeholder: 'GGG',
                size: '3',
                value: '',
                dataindex: '0',
                side: 'back',
            },
        ],
        fieldGroups: [
            {
                validRanges: [['000', '999']],
                frontNumberFields: [
                    {
                        name: 'validation',
                        placeholder: 'VVVVVVVVVVVV',
                        size: '12',
                        value: '',
                        dataindex: '5',
                        side: 'front',
                    },
                ],
                backNumberFields: [
                    {
                        name: 'packNum',
                        placeholder: 'BBBBB',
                        size: '5',
                        value: '',
                        dataindex: '1',
                        side: 'back',
                    },
                    {
                        name: 'checkDigit',
                        placeholder: 'X',
                        size: '1',
                        value: '',
                        dataindex: '2',
                        side: 'back',
                    },
                    {
                        name: 'ticketNum',
                        placeholder: 'TTT',
                        size: '3',
                        value: '',
                        dataindex: '3',
                        side: 'back',
                    },
                    {
                        name: 'endCheckDigits',
                        placeholder: 'XX',
                        size: '2',
                        value: '',
                        dataindex: '4',
                        side: 'back',
                    },
                ],
            },
        ],
    },
    nextDrawingsLimitOnePerSweepstake: true,
    nextDrawingsMaxElement: 5,
    epochEndDate: 2147385600, // Jan 18, 2038 Midnight
    banners: {
        homeCarousel: 'dwb_home_page_carousel',
    },
    apiClientConfig: {
        appUrl: '',
        routes: {
            getConfig: 'ctlEnsemble.getConfig',
            login: 'loyalty2.ptaLogin',
            // login: 'ctlEnsemble.login',
            logout: 'ctlEnsemble.logout',
            checkTicket: 'mrbEnsemble.checkTicket',
            enterIGT24Ticket: 'ctlEnsemble.enterTicket',
            enterDrawTicket: 'mrbEnsemble.enterTicket',
            enterScratchTicket: 'mrbEnsemble.enterTicket',
            enterFastPlayTicket: 'mrbEnsemble.enterTicket',
            getEntries: 'scbzApi.getEntries',
            getPlayerData: 'ctlEnsemble.getPlayerData',
            setEntryFlashGameFlag: 'scbzApi.setEntryFlashGameFlag',
            update: 'ctlEnsemble.update',
            register: 'mrbEnsemble.register',
            verify: 'ctlEnsemble.verify',
            confirm: 'ctlEnsemble.confirm',
            confirmResend: 'ctlEnsemble.confirmResend',
            winners: 'ctlEnsemble.winners',
            postFeedback: 'ctlEnsemble.postFeedback',
            getFeedbackCategories: 'ctlEnsemble.getFeedbackCategories',
            forgotPassword: 'ctlEnsemble.forgotPassword',
            recoverPassword: 'ctlEnsemble.recoverPassword',
            getGame: 'fauxScbzApi.getGame',
            getGameOver: 'fauxScbzApi.getGameOver',
            getAchievements: 'ctlEnsemble.getAchievements',
            getPoints: 'ctlEnsemble.getPoints',
            getActivity: 'ctlEnsemble.getActivity',
            getReward: 'ctlEnsemble.getReward',
            getCoupons: 'mrbEnsemble.getCoupons',
            getPromotions: 'ctlEnsemble.getPromotions',
            getPlayerPromotions: 'ctlEnsemble.getPlayerPromotions',
            getPointsForDrawings: 'loyalty2.getPointsForDrawings',
            getPlayerPFDTransactions: 'loyalty2.getPlayerPFDTransactions',
            redeemPFDPoints: 'loyalty2.redeemPFDPoints',
            getSweeps: 'ctlEnsemble.getSweeps',
            getSweepDetails: 'mrbEnsemble.getSweepDetails',
            allocateEntries: 'mrbEnsemble.allocateEntries',
            getActiveSweeps: 'ctlEnsemble.getActiveSweeps',
            getCurrentSweeps: 'ctlEnsemble.getCurrentSweeps',
            getExpiredSweeps: 'ctlEnsemble.getExpiredSweeps',
            getRevealToken: 'ctlEnsemble.getRevealToken',
            getBanner: 'ctlEnsemble.getBanner',
            expiredDrawings: 'ctlEnsemble.recentDrawings',
            currentDrawings: 'ctlEnsemble.currentDrawings',
            nextDrawings: 'ctlEnsemble.nextDrawings',
            getAllOptins: 'mrbEnsemble.getAllOptins',
            getOptins: 'mrbEnsemble.getOptins',
            getOptinsByCategory: 'mrbEnsemble.getOptinsByCategory',
            optinSubscribe: 'mrbEnsemble.optinSubscribe',
            optinUnsubscribe: 'mrbEnsemble.optinUnsubscribe',
            updateOptins: 'mrbEnsemble.updateOptins',
            sendRafInvite: 'mrbEnsemble.sendRafInvite',
            getRafCode: 'mrbEnsemble.getRafCode',
            getActiveSurveys: 'mrbEnsemble.getActiveSurveys',
            getSurveyStatus: 'mrbEnsemble.getSurveyStatus',
            getSurveyQuestions: 'mrbEnsemble.getSurveyQuestions',
            getNamedSurveyQuestions: 'mrbEnsemble.getNamedSurveyQuestions',
            setSurveyAnswer: 'mrbEnsemble.setSurveyAnswer',
            completeSurvey: 'mrbEnsemble.completeSurvey',
            getDashboard: 'mrbEnsemble.getDashboard',
            submitSocialMediaClick: 'mrbEnsemble.submitSocialMediaClick',
            getPlayerAllocatableEntries: 'mrbEnsemble.getPlayerAllocatableEntries',
            getPointsHistory: 'mrbEnsemble.getPointsHistory',
            actionComplete: 'mrbEnsemble.actionComplete',
            callExternalEndpoint: 'mrbEnsemble.callExternalEndpoint',
            getThirdPartyPrizes: 'mrbEnsemble.getThirdPartyPrizes',
            getThirdPartyPrizeBarcode: 'mrbEnsemble.getThirdPartyPrizeBarcode',
            getTicketActivity: 'mrbEnsemble.getTicketActivity',
            updateEmail: 'mrbEnsemble.updateEmail',
            updatePassword: 'mrbEnsemble.updatePassword',
            deletePlayerAccount: 'loyalty2.deletePlayerAccount',
        },
    },
};
