//* Ovveride ticket status if needed
// Note - Ticket Services might be okay with a ticket, but bonusing could complain, such in the case of a Duplicate
// in bonusing which would show in the ticketDetails.    In these cases where bonusing deems the ticket as not
// promotion eligible throw an error and don't take to post claim.
//
// This logic should really be moved out of the front-end and into a middleware or into TS itself.
const validateTicketResponse = (response) => {
    //* Override status: DAILY_ENRTY_EXCEEDED
    if (
        response.success &&
        response.entry?.TicketDetails?.TicketStatus === 'NOT_A_WINNER' &&
        Array.isArray(response.entry?.Bonus) &&
        response.entry.Bonus.length === 0
    ) {
        response.entry.TicketDetails.TicketStatus = 'DAILY_ENRTY_EXCEEDED';
    }
    // If the ticket is not promotion eligible, warn user that its an invalid ticket
    else if (response.success && response.entry?.TicketDetails?.IsPromotionEligible === 'No') {
        response.success = false;
        response.message = response.entry?.TicketDetails?.TicketStatus ?? '_INVALID_TICKET';
    } else if (
        response.success &&
        (response.entry?.TicketDetails?.IsPromotionEligible === 'Duplicate' || response.entry?.TicketDetails?.TicketStatus === 'Duplicate')
    ) {
        response.success = false;
        response.message = '_DUPLICATE_TICKET';
    } else if (response?.entry?.Bonus && ((Array.isArray(response?.entry?.Bonus) && response?.entry?.Bonus.length === 0) || response.entry.Bonus === null)) {
        response.success = false;
        response.message = '_NOT_AVAILABLE';
    }

    return response;
};

export default validateTicketResponse;
