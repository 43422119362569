import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isJSON, getCMSObject, ActionNotification } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import LoadingIndicator from '../Components/LoadingIndicator';

/********************************************************************************************************
 * Component: UserLockout
 * Purpose:   Handled redirection policy for various actions on the
 *            users profile.   Each action should force the user
 *            to a specific page to complete the action required
 *
 * Props: -   children -  reference to the page the user wanted to navigate to
 *            loading  -  reference to the loading store
 *            user     -  reference to the user store
 *            config   -  reference to the config store
 *            currentSection -  Identifies the current section of the page we are on
 *
 * APIs used:  none
 */

const playerActionConfiguration = getCMSObject('data.playerActionSection') ?? {};

const UserLockout = ({ children, loading, user, currentSection }) => {
    const [hasMounted, setHasMounted] = useState(false);
    const playerActions = playerActionConfiguration?.playerActions ?? [];

    //? Pages where we skip action execution
    const skipActionExecution = (action) => {
        const options = playerActions.find((item) => item.actionType === action);
        const skipExecution = isJSON(options.actionSkipExecution) ? JSON.parse(options.actionSkipExecution) : [];

        return skipExecution.includes(currentSection) ? true : false;
    };

    //? Pages where players can resolve this action
    const canActionResolved = (action) => {
        const options = playerActions.find((item) => item.actionType === action);
        const canResolved = isJSON(options.actionCanResolved) ? JSON.parse(options.actionCanResolved) : [];

        return canResolved.includes(currentSection) ? true : false;
    };

    useEffect(() => {
        setHasMounted(true);
    }, [loading, hasMounted, user]);

    let userActions = user?.player?.actions ?? [];

    //? For testing: to force an action notification
    // userActions = [ ...userActions, 'pending-delete'];
    // userActions = [ ...userActions, 'update-account'];
    // userActions = [...userActions, 'manual-verify'];
    // userActions = [...userActions, 'account-confirm'];
    // userActions = [ ...userActions, 'verify-account'];
    // userActions = [...userActions, 'change-password'];
    // userActions = [...userActions, 'review-optins'];
    // userActions = [...userActions, { 'take-survey': 'survey-id' }];
    // userActions = [...userActions, 'tos'];

    if (!hasMounted || loading.actions['getConfig'] || loading.actions['login']) {
        return <LoadingIndicator priority='normal' />;
    } else {
        if (user.loggedIn) {
            if (userActions?.length > 0) {
                let currentAction = '';

                //? Requested account delete
                currentAction = 'pending-delete';
                if (userActions.includes(currentAction) && !skipActionExecution(currentAction)) {
                    if (canActionResolved(currentAction)) {
                        return children;
                    } else {
                        return <ActionNotification action={currentAction} userActions={userActions} options={playerActionConfiguration} />;
                    }
                }

                //? Registration step two (need to add more user information)
                currentAction = 'update-account';
                if (userActions.includes(currentAction) && !skipActionExecution(currentAction)) {
                    if (canActionResolved(currentAction)) {
                        return children;
                    } else {
                        return <ActionNotification action={currentAction} userActions={userActions} options={playerActionConfiguration} />;
                    }
                }

                //? Registration step four (need to verify account)
                currentAction = 'verify-account';
                if (userActions.includes(currentAction) && !skipActionExecution(currentAction)) {
                    if (canActionResolved(currentAction)) {
                        return children;
                    } else {
                        return <ActionNotification action={currentAction} userActions={userActions} options={playerActionConfiguration} />;
                    }
                }

                //? Registration step four (need to verify account)
                currentAction = 'manual-verify';
                if (userActions.includes(currentAction) && !skipActionExecution(currentAction)) {
                    if (canActionResolved(currentAction)) {
                        return children;
                    } else {
                        return <ActionNotification action={currentAction} userActions={userActions} options={playerActionConfiguration} />;
                    }
                }

                //? Registration step four (need to verify email)
                currentAction = 'account-confirm';
                if (userActions.includes(currentAction) && !skipActionExecution(currentAction)) {
                    if (canActionResolved(currentAction)) {
                        return children;
                    } else {
                        return <ActionNotification action={currentAction} userActions={userActions} options={playerActionConfiguration} />;
                    }
                }

                //? Password is expired, needed to change
                currentAction = 'change-password';
                if (userActions.includes(currentAction) && !skipActionExecution(currentAction)) {
                    if (canActionResolved(currentAction)) {
                        return children;
                    } else {
                        return <ActionNotification action={currentAction} userActions={userActions} options={playerActionConfiguration} />;
                    }
                }

                //? Terms of service was updated, player needs to agree
                currentAction = 'tos';
                if (userActions.includes(currentAction) && !skipActionExecution(currentAction)) {
                    if (canActionResolved(currentAction)) {
                        return children;
                    } else {
                        return <ActionNotification action={currentAction} userActions={userActions} options={playerActionConfiguration} />;
                    }
                }

                //? Take a survey: player needs to take a survey
                currentAction = 'take-survey';
                if (userActions?.[0]?.[currentAction] && Object.keys(userActions?.[0]).includes(currentAction) && !skipActionExecution(currentAction)) {
                    if (canActionResolved(currentAction)) {
                        return children;
                    } else {
                        const survey = userActions?.[0]?.[currentAction];
                        return (
                            <ActionNotification
                                action={currentAction}
                                attributes={{ '%URL_ID%': survey }}
                                userActions={userActions}
                                options={playerActionConfiguration}
                            />
                        );
                    }
                }

                //? Opt-in review: player needs to review their opt-ins
                currentAction = 'review-optins';
                if (userActions.includes(currentAction) && !skipActionExecution(currentAction)) {
                    if (canActionResolved(currentAction)) {
                        return children;
                    } else {
                        return <ActionNotification action={currentAction} userActions={userActions} options={playerActionConfiguration} />;
                    }
                }

                return children;
            } else {
                return children;
            }
        } else {
            return children;
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLockout);
