import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const Login = ({ user, config, cmsSourceFirebase, actions, match }) => {
    useEffect(() => {
        if (config.config && user?.loaded && !user.loggedIn) {
            handleLogin();
        }
    }, [config, user]);

    const handleLogin = async () => {
        let myLocation = window.location.protocol + '//' + window.location.host + '/login-pta';

        // remove any trailing slashes
        myLocation = myLocation.replace(/\/$/, '');

        if (config.config?.login_host_uri) {
            const loginUrl = `${config.config.login_host_uri}?PtaUrl=${myLocation}`;
            window.location.replace(loginUrl);
        }
    };

    return <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>{user?.loaded && user.loggedIn ? <Redirect to='/enter' /> : null}</Layout>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
