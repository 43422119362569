import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const MyProfilePage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    useEffect(() => {
        if (config.config && user?.loaded && user.loggedIn) {
            handleMyProfile();
        }
    }, [config, user]);

    const myProfileUrl = config.config?.my_profile_host_uri ?? null;

    const handleMyProfile = async () => {
        if (myProfileUrl) {
            window.location.replace(myProfileUrl);
        }
    };

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            {user?.loaded && user.loggedIn ? null : <Redirect to='/login' />}
            <div className='redirect-container'>
                <p className='redirect-message'>Redirecting to My Profile page:</p>
                <a href={myProfileUrl} className='redirect-link'>
                    {myProfileUrl}
                </a>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfilePage);
